import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/head-of-service/1.jpeg';
import blogd2 from '../../assets/images/2022/head-of-service/2.jpeg';
import blogd3 from '../../assets/images/2022/head-of-service/3.jpeg';
import blogd4 from '../../assets/images/2022/head-of-service/4.jpeg';
import blogd5 from '../../assets/images/2022/head-of-service/5.jpeg';
import blogd6 from '../../assets/images/2022/head-of-service/6.jpeg';
import blogd7 from '../../assets/images/2022/head-of-service/7.jpeg';
import blogd8 from '../../assets/images/2022/head-of-service/8.jpeg';
import blogd9 from '../../assets/images/2022/head-of-service/9.jpeg';
import blogd10 from '../../assets/images/2022/head-of-service/11.jpeg';
import blogd11 from '../../assets/images/2022/head-of-service/10.jpeg';
import blogd12 from '../../assets/images/2022/head-of-service/12.jpeg';


import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Federal Ministry of Health Held Seminar in LUTH: Patients to
                  Access Affordable Surgical Care
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Head of Service, Dr Folashade Yemi-Esan, DG Budget, Dr Ben
                  Akabueze and Dr David Atuwo, the Representative of the HMH,
                  were in LUTH on the 16th of December to commission three
                  Legacy Projects:
                  <ul>
                    <li>
                      30-bed ICU, including a 4-bed <br /> PICU and a 2-cot NICU{' '}
                    </li>
                    <li>︎Rehabilitated Labor Ward/ New Neonatal Unit</li>
                    <li>
                      60-bed Alima Atta Oncology bed built and equipped at over
                      N1.5 billion by Mrs Iyabo Atta in memory of her daughter
                      who died of cancer.{' '}
                    </li>
                  </ul>
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  They lauded LUTH for its judicious use of budgeted funds and
                  promised to quickly assist our hospitals fast-track the
                  approval process for additional staff to run these new
                  facilities.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  An attendee making a comment at the seminar
                </span>
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  She said that NSOANP had to do with providing affordable,
                  accessible and safe surgery to Nigerians that need it as part
                  of Universal Health Coverage. “This is as a result of World
                  Health Assembly Resolution in 2015 which mandated Member
                  States to include basic and Surgical Care as part of Universal
                  coverage in the country. “Federal Ministry of Health produced
                  the plan and most of the implementation takes place at the
                  State level. “That is why we started the dissemination to the
                  36 States and the Federal Capital Territory after approval was
                  given at the National Council of Health,”. She said that World
                  Health Assembly Resolution 68:15 says Surgical, Obstetrics,
                  Anesthesia and Nursing care are important components of
                  Universal Health Coverage, therefore, Surgeries are fully
                  integrated into Healthcare Services.
                </p> */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
